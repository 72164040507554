import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import Button, { ButtonProps } from 'src/components/button/button';
import { getEnvConfig } from 'src/config/config';

import { CHECK_UPLOAD_STATE, GENERATE_CSV_REPORT } from './generate-report-queries';
import * as styles from './generate-report.module.less';

export interface GenerateReportProps {
  /**
   * IMPORTANT!
   * Any queries made to the reporting service, need to be formatted so that they accept both `limit` and `skip` as variables from
   * `reportQueryVariables`, as these are overridden by the reporting service when making the query. This is especially important if
   * a `totalLimit` is not passed in the `paginator` prop, as it would cause an infinite loop.
   *
   * Example:
   *
   * query SearchPlayers(`$limit: Int!`
   * `$skip: Int!`
   * $filter: PersonFilterOptionsInput
   * $sort: SortOrder) {
   * ...
   * }`
   */
  reportQuery: string;
  reportQueryEndpoint: string;
  reportQueryVariables?: any;
  outputFormat?: 'CSV' | 'JSON' | 'NDJSON';
  generateButtonTitle?: React.ReactNode;
  buttonProps?: ButtonProps;
  paginator?: AddReportPaginator;
  csvFormatOptions?: CSVFormatOptions;
  filename?: string;
  onReportUploadStateChange?: OnReportUploadStateChange;
  reportUploadState?: ReportUploadState;
  csvTransforms?: CSVTransform[];
}

export type OnReportUploadStateChange = (state: ReportUploadState, info?: { downloadUrl?: string }) => void;
export type ReportUploadState = 'processing' | 'downloadable' | 'none';

export interface AddReportPaginator {
  rootFieldPath: string;
  initialSkip?: number;
  totalLimit?: number;
  pageSize?: number;
}

export interface CSVTransform {
  key: string;
  valueMap?: TransformValueMap[];
  transform?: CSVValueTransform;
  transforms?: CSVValueTransformInput[];
  label?: string;
}

export interface CSVValueTransformInput {
  operation: CSVValueTransform;
  parameters?: CSVValueTransformInputParameters[];
}

export interface TransformValueMap {
  in: string;
  out: string;
}

export enum CSVValueTransform {
  DIVIDE_100 = 'DIVIDE_100',
  TO_DATE_TIME = 'TO_DATE_TIME',
  ARRAY_JOIN = 'ARRAY_JOIN',
  ARRAY_FILTER = 'ARRAY_FILTER',
  FORMAT_UTC_DATE = 'FORMAT_UTC_DATE',
  ARRAY_FIELD_SELECT = 'ARRAY_FIELD_SELECT',
  CONVERT_TO_TIMEZONE = 'CONVERT_TO_TIMEZONE',
}

export interface CSVValueTransformInputParameters {
  key: string;
  value: string;
}

export interface CSVFormatOptions {
  blankOutRepeated?: boolean;
  disableUnwind?: boolean;
}

const GenerateReport: React.FC<GenerateReportProps> = ({
  generateButtonTitle,
  reportQuery,
  reportQueryEndpoint,
  outputFormat = 'CSV',
  buttonProps = {},
  paginator,
  onReportUploadStateChange,
  reportUploadState: controlledReportState,
  reportQueryVariables,
  csvTransforms,
  csvFormatOptions,
  filename,
}) => {
  const [internalReportState, setInternalReportState] = useState<ReportUploadState>('none');
  const reportState = controlledReportState ?? internalReportState;

  const queryVariablesJSON = useMemo(() => {
    try {
      if (reportQueryVariables) return JSON.stringify(reportQueryVariables);
    } catch {
      return undefined;
    }
  }, [reportQueryVariables]);

  const [generateCSV, { data }] = useMutation(GENERATE_CSV_REPORT, {
    client: meshGatewayClient,
    variables: {
      query: reportQuery,
      endpoint: reportQueryEndpoint,
      paginator,
      outputFormat,
      queryVariablesJSON,
      csvTransforms,
      csvFormatOptions,
    },
  });

  const providerId = useOrgId();

  const downloadLink = useMemo(() => {
    const filenameParam = filename ? `&originalname=${filename}.${outputFormat.toLowerCase()}` : '';

    // Swift proxy url ends with "?", in which case remove leading "/" from report route
    const gatewayUrl = getEnvConfig().GATEWAY_BASE_URL;
    let reportRoute: string = data?.addReport?.url ?? '';
    if (gatewayUrl.endsWith('?')) reportRoute = reportRoute.replace(/^\/+/, '');

    return `${gatewayUrl}${reportRoute}?providerId=${providerId}${filenameParam}`;
  }, [data, filename, outputFormat]);

  const updateReportState = useCallback(
    (uploadState: ReportUploadState) => {
      const uploadInfo = uploadState === 'downloadable' ? { downloadUrl: downloadLink } : undefined;
      onReportUploadStateChange?.(uploadState, uploadInfo);
      setInternalReportState(uploadState);
    },
    [setInternalReportState, onReportUploadStateChange, downloadLink],
  );

  const [awaitUpload, awaitUploadResult] = useLazyQuery(CHECK_UPLOAD_STATE, {
    client: meshGatewayClient,
    pollInterval: 2000,
  });

  const generateReport = useCallback(async () => {
    updateReportState('processing');
    try {
      const reportId = (await generateCSV()).data?.addReport?.id;
      if (reportId) {
        awaitUpload({ variables: { reportId } });
      } else {
        updateReportState('none');
      }
    } catch {
      updateReportState('none');
    }
  }, [generateCSV, awaitUpload, updateReportState]);

  useEffect(() => {
    const uploadState = awaitUploadResult.data?.reportMetadata?.status?.state;
    const uploaded = awaitUploadResult.data?.reportMetadata?.uploaded;

    if (uploaded || uploadState === 'ERROR' || uploadState === 'COMPLETE') {
      awaitUploadResult?.stopPolling?.();
      updateReportState(uploaded ? 'downloadable' : 'none');
    }
  }, [awaitUploadResult, updateReportState]);

  const { t } = useTranslation();

  return reportState === 'downloadable' ? (
    <a download href={downloadLink} className={styles.exportLink}>
      {t('download report', { type: outputFormat })}
    </a>
  ) : (
    <Button {...buttonProps} loading={reportState === 'processing'} onClick={generateReport}>
      {generateButtonTitle ?? t('generate report', { type: outputFormat })}
    </Button>
  );
};

export default GenerateReport;
