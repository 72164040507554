import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import PayoutsReportTransactions from 'src/components/payouts-report-transactions/payouts-report-transactions';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const PayoutsReportTransactionsPage = () => {
  return (
    <Router basepath="tournaments/payouts-report/">
      <PayoutsReportTransactionsRoute path="/:payoutsReportId" />
    </Router>
  );
};

interface PayoutsReportTransactionsProps extends RouteComponentProps {
  payoutsReportId?: string;
}

const PayoutsReportTransactionsRoute: React.FC<PayoutsReportTransactionsProps> = ({ payoutsReportId }) => {
  return (
    <Layout>
      <SEO title="Payouts Report Transactions" />
      <PrivateRoute>{payoutsReportId && <PayoutsReportTransactions payoutsReportId={payoutsReportId} />}</PrivateRoute>
    </Layout>
  );
};

export default PayoutsReportTransactionsPage;
